<template>
  <v-container fluid>
    <v-btn @click="goTo('campo')">
      Campo
    </v-btn>
    <v-btn @click="goTo('moderador')">
      Moderação
    </v-btn>
  </v-container>
</template>
<script>

export default {
  name: "AppHome",
  data() {
    return {

    }
  },
  methods: {
    goTo(to) {
      if (to == 'campo') {
        this.$router.push("/app/campo");
      } else {
        this.$router.push("/app/moderador");
      }
    },
  }
}

</script>